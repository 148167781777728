import React, { useEffect } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  OverlayView,
} from "react-google-maps";

// import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
// import SearchBox from "react-google-maps/lib/components/places/SearchBox";

import { useSelector } from "react-redux";
import { socket } from "src/socket/useWebSocket";

const MapComponent = () => {
  const trackingLocationDeliveryRedux = useSelector(
    (state) => state.trackingLocationDelivery.data
  );

  useEffect(() => {
    socket.emit("requestGetDataTrackingLocationDelivery");
  }, []);

  return (
    <div>
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat: 9.96047, lng: 105.117962 }}
      >
        {trackingLocationDeliveryRedux.map(
          (element) =>
            element.location && (
              <OverlayView
                position={{
                  lat: parseFloat(element.location.latitude),
                  lng: parseFloat(element.location.longitude),
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div
                  style={{
                    position: "absolute",
                    transform: "translate(-50%, -120%)",
                    background: "rgba(0, 0, 0, 0.7)",
                    color: "white",
                    width: "35px",
                    height: "35px",
                    padding: "5px 10px",
                    borderRadius: "20px",
                    border: "4px solid #208000",
                    fontSize: "12px",
                    fontWeight: "bold",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {element.user.code}
                </div>
              </OverlayView>
            )
        )}
      </GoogleMap>
    </div>
  );
};

export default withScriptjs(withGoogleMap(MapComponent));
